// BlogComponent.js

import React from "react";
import { imageUrl } from "../helperFunction";
import "./BlogComponent.css";

const BlogComponent = ({ blogs }) => {
  // const [expandedBlogs, setExpandedBlogs] = useState([]);

  // const toggleExpand = (index) => {
  //   setExpandedBlogs((prevExpanded) => {
  //     const newExpanded = [...prevExpanded];
  //     newExpanded[index] = !newExpanded[index];
  //     return newExpanded;
  //   });
  // };
  return (
    <div className="blog-container">
      {blogs.map((blog, index) => (
        <div key={index} className="blog-card">
          <img
            src={imageUrl(blog.image)}
            alt={blog.title}
            className="blog-image"
          />
          <div className="blog-content">
            <div className="blog-date-text">{blog.date}</div>
            <h2 className="blog-title">{blog.title}</h2>
            <p className="blog-text">{blog.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogComponent;
