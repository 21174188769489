import React, { useState } from "react";
import "./EmailComposerModal.css";
import emailjs from "@emailjs/browser";

function EmailComposerModal({ isOpen, onClose, onSubmit }) {
  const [selectedOption, setSelectedOption] = useState("none");
  const [message, setMessage] = useState("");
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let template_params = {
      user_name: `${fName} ${lName}`,
      user_email: email,
      subject: selectedOption,
      message: message,
    };

    emailjs
      .send(
        "service_eykeu48",
        "template_jufj6p7",
        template_params,
        "OHyO8m3phkbelGP1O",
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        },
      );
    onSubmit({ fName, lName, selectedOption, message });
    onClose();
    clearData();
  };

  const clearData = () => {
    setSelectedOption("none");
    setMessage("");
    setFname("");
    setLname("");
    setEmail("");
  };

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Get In Touch</h2>
        <form onSubmit={handleSubmit}>
          {/* <label htmlFor="fname">First Name:</label> */}
          <input
            type="text"
            id="fname"
            name="fname"
            placeholder="First Name"
            value={fName}
            onChange={(e) => setFname(e.target.value)}
            required
          />
          {/* <label htmlFor="lname">Last Name:</label> */}
          <input
            type="text"
            id="lname"
            name="lname"
            placeholder="Last Name"
            value={lName}
            onChange={(e) => setLname(e.target.value)}
            required
          />
          {/* <label htmlFor="email">Your Email:</label> */}
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {/* <label htmlFor="subject">Subject:</label> */}
          <select
            className="options"
            style={{color: selectedOption === 'none' ? '#C7CACD' :'#000'}}
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <option value="none" selected hidden>Subject</option>
            <option value="parking_solutions">Parking Solutions</option>
            <option value="garage_service">Garage Service</option>
            <option value="washing_service">Washing Service</option>
          </select>

          {/* <label htmlFor="message">Message:</label> */}
          <textarea
            id="message"
            name="message"
            rows="6"
            placeholder=" Write Your Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>

          <button onClick={handleSubmit} className="buttonBtn" type="button">
            Send
          </button>
        </form>
      </div>
    </div>
  );
}

export default EmailComposerModal;
