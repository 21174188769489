import React, { useState } from "react";
import "./styles.css";
import Header from "./components/common/Navbar";
import Footer from "./components/common/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CombineCom from "./components/main/CombineCom";
import DetailServicePage from "./components/main/DetailsServicePage";
import EmailComposerModal from "./components/common/modal/EmailComposerModal";
import Career from "./components/main/Career";
import BlogPage from "./components/main/BlogPage";

export default function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleEmailSubmit = (emailData) => {
    console.log("Email data:", emailData);
  };
  return (
    <BrowserRouter>
    <div style={{backgroundColor:"#000", height:"90px"}}></div>
      <Header />
      <div className="enquiry-pill" onClick={openModal}>
        <div className="enquiry-pill-icon-div">
        <img src="/assets/Images/enquiry.png" className="enquiry-pill-icon" alt="contact"/>
        </div>
        <div className="enquiry-pill-text">Business Enquiry</div>
      </div>
      <EmailComposerModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleEmailSubmit}
      />
      <Routes>
        <Route path="/" element={<CombineCom />} exact />
        <Route path="/services/:type" element={<DetailServicePage />} exact />
        <Route path="/career" element={<Career />} exact />
        <Route path="/blog/:index" element={<BlogPage />} exact />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
