// JobDetails.js

import React from "react";
import "./JobDetails.css";

const JobDetails = ({ match }) => {
  // In a real-world scenario, you might fetch the job details based on the ID from an API
  const jobId = "JR676"; // Assuming you have a route parameter for the job ID

  // Example job details
  const jobDetails = {
    id: jobId,
    title: "Software Engineer",
    company: "Tech Co.",
    location: "Cityville, USA",
    description:
      "Developing and maintaining web applications. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac luctus dolor, eget ullamcorper metus. Proin eget venenatis nunc. Sed ut sagittis elit.",
    requirements: [
      "Bachelor's degree in Computer Science",
      "Experience with JavaScript and React",
      "Strong problem-solving skills",
    ],
    contact: "jobs@techco.com",
  };

  return (
    <div className="job-details-container">
      <h2>{jobDetails.title}</h2>
      <p>
        <strong>Company:</strong> {jobDetails.company}
      </p>
      <p>
        <strong>Location:</strong> {jobDetails.location}
      </p>
      <p>{jobDetails.description}</p>

      <h3>Requirements:</h3>
      <ul>
        {jobDetails.requirements.map((requirement, index) => (
          <li key={index}>{requirement}</li>
        ))}
      </ul>

      <p>
        <strong>Contact:</strong> {jobDetails.contact}
      </p>
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSdN6MS2FJpt7MNi3WCDOHGs4Q8ZfRkXp_Qiy4eRi3g-9xy3rA/viewform?usp=sf_link"
        target={"_blank"}
        rel="noreferrer"
      >
        Submit Application
      </a>
    </div>
  );
};

export default JobDetails;
