import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getStaticData } from "../common/helperFunction";

const BlogPage = () => {
  const { index } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const blogsData = getStaticData("blogs");
      const blogIndex = parseInt(index, 10);

      if (isNaN(blogIndex) || blogIndex < 0 || blogIndex >= blogsData.length) {
        console.error("Invalid blog index");
        return;
      }
      const selectedBlog = blogsData[blogIndex];
      setBlog(selectedBlog);
    };

    fetchData();
  }, [index]);

//   const blogImage=blog.image;
  console.log(blog)

  if (!blog) {
    return <div>Loading...</div>; // You might want to show a loading state while fetching data
  }

  return (
    <div style={{padding:"16px", display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}>
      <div style={{color:"#000", fontSize:"25px", fontWeight:"600", margin:"16px 0px"}}>{blog.title}</div>
      <img alt={blog.alt} src={`/${blog.image}`} style={{ width: "75%"}} />
      <div style={{margin:"16px 0px", padding:"0px 50px"}}>{blog.content}</div>
      {/* Other blog details */}
    </div>
  );
};

export default BlogPage;
