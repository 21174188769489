import React from "react";
// import Header from "../common/Navbar";
import About from "./About";
import Blog from "./Blog";
import Career from "./Career";
import Home from "./Home";
import Services from "./Services";

const CombineCom = () => {
  return (
    <>
      {/* <Header /> */}
      <Home />
      <Services />
      <About />
      <Blog />
      {/* <Career /> */}
    </>
  );
};
export default CombineCom;
