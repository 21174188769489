import React, { useEffect, useState } from "react";
import {
  getSocialMediaLinks,
  getWhatsappurl,
  imageUrl,
} from "./helperFunction";
import { IconContext } from "react-icons";
import { RxHamburgerMenu, RxCross1 } from "react-icons/rx";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import {
  RiFacebookFill,
  RiWhatsappFill,
  RiTwitterXFill,
  RiLinkedinFill,
} from "react-icons/ri";
import EmailComposerModal from "./modal/EmailComposerModal";
import { useNavigate } from "react-router-dom";

function Header() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [openDrawar, setOpenDrawar] = useState(false);
  const navigate = useNavigate();
  const iconStyleValue = { size: 28, style: { color: "white" } };
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [activeTab, setActiveTab] = useState("Home");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Adjust these values according to your section positions and heights
      const aboutSection = document.getElementById("about");
      const blogSection = document.getElementById("blog");
      const servicesSection = document.getElementById("services");
      // const careerSection = document.getElementById("career");

      if (aboutSection && blogSection && servicesSection /*&& careerSection*/) {
        const aboutOffset = aboutSection.offsetTop;
        const blogtOffset = blogSection.offsetTop;
        const servicesOffset = servicesSection.offsetTop;
        // const careerOffset = careerSection.offsetTop;

        if (scrollPosition < aboutOffset - 200 && activeTab !== "Home") {
          setActiveTab("Home");
        } else if (
          scrollPosition >= aboutOffset - 200 &&
          scrollPosition < blogtOffset - 200 &&
          activeTab !== "About"
        ) {
          setActiveTab("About");
        } else if (
          scrollPosition >= blogtOffset - 200 &&
          scrollPosition < servicesOffset - 200 &&
          activeTab !== "Blogs"
        ) {
          setActiveTab("Blogs");
        } else if (
          scrollPosition >= servicesOffset - 200 &&
          // scrollPosition < careerOffset - 200 &&
          activeTab !== "Services"
        ) {
          setActiveTab("Our Services");
        } 
        // else if (
        //   scrollPosition >= careerOffset - 200 &&
        //   activeTab !== "Careers"
        // ) {
        //   setActiveTab("Careers");
        // }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeTab]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({ top: section.offsetTop - 90, behavior: "smooth" });
    }
  };
  const sideNabBarOptionClicked = (sectionId) => {
    setOpenDrawar(!openDrawar);
    scrollToSection(sectionId);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleEmailSubmit = (emailData) => {
    console.log("Email data:", emailData);
  };
  const sideNabBarGetInTouchClicked = () => {
    setOpenDrawar(!openDrawar);
    openModal();
  };
  const handleScrollChange = (navigateTo) => {
    if (document.getElementById("about")) {
      scrollToSection(navigateTo);
    } else {
      navigate("/");
      setTimeout(() => {
        scrollToSection(navigateTo);
      }, 500);
    }
  };
  const sideNabBarOptionClickedHandler = (navigateTo) => {
    if (document.getElementById("about")) {
      sideNabBarOptionClicked(navigateTo);
    } else {
      navigate("/");
      setTimeout(() => {
        sideNabBarOptionClicked(navigateTo);
      }, 500);
    }
  };
  return (
    <>
      <header
        className={`flex w-full justify-between items-center px-5 lg:px-10 py-5 top-0 z-[101] fixed font-medium drop-shadow ease-in duration-200 ${
          scrollPosition > 200
            ? "bg-white text-black"
            : "bg-transparent text-white"
        }`}
      >
        <div className="flex items-center gap-4">
          <div>
            <span>
              <img
                alt="logo"
                src={imageUrl("assets/Images/logo.svg")}
                className="cursor-pointer"
                style={{ width: 50 }}
              />
            </span>
          </div>
          <div className={`${openDrawar ? "hideContent" : ""} logoText`}>
            <div>
              <img alt="logo-text" src="assets/Images/Mask group (2).png" className="main-logo"/>
              {/* <span>Gupio</span> */}
            </div>
          </div>
        </div>
        <nav className="hidden lg:flex lg:flex-[.8] 2xl:flex-[.5]">
          <ul className="flex flex-1 gap-2 items-center justify-between">
            <li
              className={activeTab === "Home" ? "active" : ""}
              onClick={() => {handleScrollChange("home");
            setActiveTab("Home")}}
            >
              Home
            </li>
            <li
              className={activeTab === "Our Services" ? "active" : ""}
              onClick={() => {handleScrollChange("services");
            setActiveTab("Our Services")}}
            >
              Our Services
            </li>
            <li
              className={activeTab === "About" ? "active" : ""}
              onClick={() => {handleScrollChange("about");
            setActiveTab("About")}}
            >
              Why Us
            </li>
            <li
              className={activeTab === "Blogs" ? "active" : ""}
              onClick={() => {handleScrollChange("blog");
            setActiveTab("Blogs")}}
            >
              Blogs
            </li>
            {/* <li
              className={activeTab === "Careers" ? "active" : ""}
              onClick={() => {navigate('/career');
            setActiveTab("Careers");}}
            >
              Careers
            </li> */}
            <li>
              <button
                onClick={openModal}
                className="border p-3 border-white hover:bg-primary hover:text-white hover:border-primary transition-two" style={{borderRadius:"10px"}}
              >
                Get In Touch
              </button>
            </li>
          </ul>
        </nav>
        <span className="sm lg:hidden 2xl:hidden">
          <IconContext.Provider
            value={{
              ...iconStyleValue,
              style: { color: `${scrollPosition > 200 ? "black" : "white"}` },
            }}
          >
            <RxHamburgerMenu onClick={() => setOpenDrawar(!openDrawar)} />
          </IconContext.Provider>
        </span>
      </header>
      {/* fixed z-[9999] top-0 bottom-0 right-0 left-0 flex items-center justify-end lg:hidden */}
      <div className={openDrawar ? "blurbg" : ""}></div>
      <div className="slider">
        {/* relative bg-white z-20 w-[85vw] h-[100vh] flex flex-col aos-init aos-animate */}
        <div className={`drawar ${openDrawar ? "show" : ""}`}>
          <div className="p-10 justify-between w-full flex">
            <div className="logoText">
            <img alt="logo-text" src="assets/Images/Mask group (2).png" className="main-logo"/>
              {/* <span>Gupio</span> */}
            </div>
            <span className="mt-2">
              <IconContext.Provider
                value={{
                  ...iconStyleValue,
                  style: {
                    cursor: "pointer",
                  },
                }}
              >
                <RxCross1 onClick={() => setOpenDrawar(!openDrawar)} />
              </IconContext.Provider>
            </span>
          </div>
          <div className="navOption">
            <ul>
              <li
                className={activeTab === "Home" ? "active" : ""}
                onClick={() => sideNabBarOptionClickedHandler("home")}
              >
                Home
              </li>
              <li
                className={activeTab === "Our Services" ? "active" : ""}
                onClick={() => sideNabBarOptionClickedHandler("services")}
              >
                Our Services
              </li>
              <li
                className={activeTab === "About" ? "active" : ""}
                onClick={() => sideNabBarOptionClickedHandler("about")}
              >
                About
              </li>
              <li
                className={activeTab === "Blogs" ? "active" : ""}
                onClick={() => sideNabBarOptionClickedHandler("blog")}
              >
                Blogs
              </li>
              {/* <li
                className={activeTab === "Careers" ? "active" : ""}
                onClick={() => sideNabBarOptionClickedHandler("career")}
              >
                Careers
              </li> */}
            </ul>
          </div>
          <hr />
          <span className="followUs">follow us</span>
          <div className="socialMedia flex justify-center">
            <div className="socialMedia_content">
              <a
                className="insta"
                href={getSocialMediaLinks("insta")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconContext.Provider
                  value={{
                    ...iconStyleValue,
                    style: {
                      cursor: "pointer",
                      color: "white",
                    },
                  }}
                >
                  <FaInstagram />
                </IconContext.Provider>
              </a>
              <a
                className="facebook"
                href={getSocialMediaLinks("fa")}
                target="_blank"
                rel="noreferrer"
              >
                <IconContext.Provider value={iconStyleValue}>
                  <RiFacebookFill />
                </IconContext.Provider>
              </a>
              <a
                className=""
                href={getSocialMediaLinks("yt")}
                target="_blank"
                rel="noreferrer"
              >
                <IconContext.Provider
                  value={{
                    ...iconStyleValue,
                    style: {
                      cursor: "pointer",
                      color: "white",
                      background: "red",
                    },
                  }}
                >
                  <FaYoutube />
                </IconContext.Provider>
              </a>
              <a
                className="linkde"
                href={getSocialMediaLinks("in")}
                target="_blank"
                rel="noreferrer"
              >
                <IconContext.Provider value={iconStyleValue}>
                  <RiLinkedinFill />
                </IconContext.Provider>
              </a>
              <a
                className=""
                href={getSocialMediaLinks("twit")}
                target="_blank"
                rel="noreferrer"
              >
                <IconContext.Provider
                  value={{
                    ...iconStyleValue,
                    style: {
                      cursor: "pointer",
                      color: "white",
                      background: "black",
                    },
                  }}
                >
                  <RiTwitterXFill />
                </IconContext.Provider>
              </a>
            </div>
          </div>
          <hr />
          <div className="flex justify-center">
            <button
              onClick={sideNabBarGetInTouchClicked}
              className="border border-black p-2 "
            >
              Get In Touch
            </button>
          </div>
          <hr />

          <p className="copyright">
            All Rights Reserved Test © Copyright 2023
          </p>
        </div>
      </div>
      <a className="whatsapp" href={getWhatsappurl()}>
        <IconContext.Provider
          value={{
            size: 30,
            style: {
              cursor: "pointer",
              color: "white",
              background: "green",
            },
          }}
        >
          <RiWhatsappFill />
        </IconContext.Provider>
      </a>
      <EmailComposerModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleEmailSubmit}
      />
    </>
  );
}

export default Header;
