import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainBanner from "../common/banner/MainBanner";
import BlogComponent from "../common/blog/BlogComponent";
import { getStaticData } from "../common/helperFunction";
import EmailComposerModal from "../common/modal/EmailComposerModal";

const DetailServicePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [details, setSteps] = useState({});
  const param = useParams();
  useEffect(() => {
    setSteps(getStaticData("serviceDetails", param.type));
  }, [param.type]);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleEmailSubmit = (emailData) => {
    console.log("Email data:", emailData);
  };
  return (
    <>
      <MainBanner>
        <div className={details?.banarData?.title==="Gupio Parking Solution"?"banner-parking-1":(details?.banarData?.title==="Gupio Garage Service"?"banner-garage-1":(details?.banarData?.title==="Gupio Washing Centre"?"banner-wash-1":"banner-driver"))}>
          <div>
            <h1 className="max-w-[600px] text-5xl font-bold" style={{color:"#fff"}}>
              {details?.banarData?.title}
            </h1>
            <p className="max-w-[600px] text-lg font-normal" style={{color:"#fff"}}>
              {details?.banarData?.description}
            </p>
            {/* <button
              onClick={openModal}
              className="border p-3 border-white hover:bg-primary hover:text-white hover:border-primary transition-two"
            >
              Get In Touch
            </button> */}
          </div>
        </div>
      </MainBanner>
      <section className="w-full px-4 py-4 sm:px-6 lg:px-8">
        <div className="servicdeDetails">
          <div className="title">{details?.title}</div>
          <div className="description">{details?.description}</div>
        </div>
        <BlogComponent blogs={details?.tiles || []} />
      </section>
      <div className="button-getin-div">
      <button className="border p-3 border-white hover:bg-primary hover:text-white hover:border-primary transition-two" style={{border:"1px solid #dcdcdc", marginBottom:"10px", borderRadius:"10px"}} onClick={openModal}>Get In Touch</button>
      </div>
      <EmailComposerModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleEmailSubmit}
      />
    </>
  );
};
export default DetailServicePage;
