import data from "./data.json";
export function imageUrl(url) {
  return `${process.env.PUBLIC_URL}/${url}`;
}
export function getWhatsappurl() {
  return "https://api.whatsapp.com/send?phone=918884446607&text=Hello!%20I%27m%20interested%20in%20your%20services.%20Could%20you%20please%20provide%20me%20with%20more%20information%20about%20your%20services%3F%20I%20would%20like%20to%20know%20more%2C%20Thank%20you!";
}
export function getSocialMediaLinks(media) {
  switch (media) {
    case "fa":
      return "https://www.facebook.com/profile.php?id=61553895278235&mibextid=ZbWKwL";
    case "insta":
      return "https://instagram.com/gupio_services?utm_source=qr&igshid=MzNlNGNkZWQ4Mg==";
    case "twit":
      return "https://twitter.com/GupioServices";
    case "in":
      return "https://www.linkedin.com/in/gupio-services-a6038a2a0?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app";
    case "yt":
      return "https://youtube.com/@GupioServices?si=ToN0zw1IGM79hvAA";
    default:
      return "";
  }
}
export function getStaticData(type, filter) {
  switch (type) {
    case "tiles":
      return data[type];
    case "blogs":
      return data[type];
    case "serviceDetails":
      return data[type][filter];
    default:
      return "";
  }
}
