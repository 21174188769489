import React from "react";
// import { IconContext } from "react-icons";
// import { FaInstagram, FaYoutube, FaMapMarked } from "react-icons/fa";
// import { RiFacebookFill, RiTwitterXFill, RiLinkedinFill } from "react-icons/ri";
import { getSocialMediaLinks, imageUrl } from "./helperFunction";
import '../common/Footer.css';

const Footer = () => {
  // const iconStyleValue = { size: 28, style: { color: "white" } };
  return (
    <div className="footer-div">
      <div className="footer-main-div">
      <div className="footer-logo-div">
      <img
        alt="logo"
        src={imageUrl("assets/Images/parking-logo.png")}
        className="cursor-pointer"
        style={{ width: "150px", marginBottom:"10px", height:"100px" }}
      />
      <div style={{color:"#C89532"}}>Gupio Services Pvt Ltd is a pioneering force in the automotive industry, dedicated to innovation, excellence, and client satisfaction. Our services span three dynamic verticals, each crafted to address diverse client needs.</div>
      </div>
      <div className="footer-links-div">
        <div style={{fontSize:"18px", fontWeight:"600", marginBottom:"10px", color:"#C89532"}}>Quick Links</div>
        <div className="grid">
        <a href="#" onClick={(e) => e.preventDefault()}>About</a>
        <a href="#">Blogs</a>
        <a href="#">Our Services</a>
        {/* <a href="#">Careers</a> */}
        </div>
      </div>
      <div className="footer-contact-div">
        <div style={{fontSize:"18px", fontWeight:"600", marginBottom:"10px", color:"#C89532"}}>Contact</div>
        <div className="footer-email">
          <img alt="mail" src="assets/Images/email (1).png" width="24px" className="footer-contact-logo" />
          <a href="mailto:solution@gupio.in">solution@gupio.in</a>
        </div>
        <div className="footer-loc">
          <img alt="mail" src="assets/Images/location.png" style={{width:"34px", height:"34px"}} className="footer-contact-logo" />
          <div>759, 3rd Floor, Sree Gururaya Mansion 8th main, JP Nagara 3rd Phase, Bengaluru, Bengaluru Urban, Karnataka, 560078 IN</div>
        </div>
        <div className="footer-social-div">
          <a href={getSocialMediaLinks('insta')} target="_blank" rel="noopener noreferrer">
            <img src="assets/Images/instagram 1.png" alt="insta"/>
          </a>
          <a href={getSocialMediaLinks('fa')} target="_blank" rel="noopener noreferrer">
            <img src="assets/Images/facebook 1.png" alt="facebook"/>
          </a>
          <a href={getSocialMediaLinks('yt')} target="_blank" rel="noopener noreferrer">
            <img src="assets/Images/youtube 1.png" alt="youtube"/>
          </a>
          <a href={getSocialMediaLinks('in')} target="_blank" rel="noopener noreferrer">
            <img src="assets/Images/linkedin 1.png" alt="linkedin"/>
          </a>
          <a href={getSocialMediaLinks('twit')} target="_blank" rel="noopener noreferrer">
            <img src="assets/Images/twitter 1.png" alt="twitter"/>
          </a>
        </div>
        </div>
      </div>
      <div className="border-t text-center md:mt-10 p-6">
         <p className="copyright">All Rights Reserved Test © Copyright 2023</p>
      </div>
    </div>





    // <footer className="bg-[#F2F2F2] w-full px-4 py-4 sm:px-6 lg:px-8">
    //   <div className="footer-container">
    //     <section>
    //       <div>
    //         <span>
    //           <img
    //             alt="logo"
    //             src={imageUrl("assets/Images/logo.svg")}
    //             className="cursor-pointer"
    //             style={{ width: 50 }}
    //           />
    //         </span>
    //       </div>
    //       <div>
    //         Gupio Services Pvt Ltd is a pioneering force in the automotive
    //         industry, dedicated to innovation, excellence, and client
    //         satisfaction. Our services span three dynamic verticals, each
    //         crafted to address diverse client needs.
    //       </div>
    //     </section>
    //     <section className="footer-mid">
    //       {/* Links in the second section */}

    //       <div className="grid">
    //         <b> Quick Links</b>
    //         <a
    //           href="#"
    //           onClick={(e) => e.preventDefault()}>
    //           About
    //         </a>
    //         <a href="#">Blogs</a>
    //         <a href="#">Our Services</a>
    //         <a href="#">Careers</a>
    //       </div>
    //     </section>
    //     <section className="flex flex-col contact">
    //       {/* Content from the first section */}

    //       {/* Other content */}
    //       <address>
    //         <b> Contact</b>
    //         <br />
    //         <div style={{display:"flex", gap:"10px"}}>
    //         <img src="assets/Images/email (1).png" width={24} alt="email"/>
    //         <a href="mailto:solution@gupio.in">solution@gupio.in</a>
    //         </div>
    //         <br />
    //         <div style={{display:"flex", gap:"10px"}}>
    //           <img src="assets/Images/location-maintenance.png" alt="location" style={{width:"30px", height:"50px"}}/>
    //         759, 3rd Floor, Sree Gururaya Mansion 8th main, JP Nagara 3rd Phase,
    //         Bengaluru, Bengaluru Urban, Karnataka, 560078
    //         <br />
    //         IN
    //         </div>
    //       </address>
    //       <div className="socialMedia flex justify-center">
    //         <div className="socialMedia_content">
    //           <a
    //             className="insta"
    //             href={getSocialMediaLinks("insta")}
    //             target="_blank"
    //             rel="noopener noreferrer">
    //             <IconContext.Provider
    //               value={{
    //                 ...iconStyleValue,
    //                 style: {
    //                   cursor: "pointer",
    //                   color: "white",
    //                 },
    //               }}>
    //               <FaInstagram />
    //             </IconContext.Provider>
    //           </a>
    //           <a
    //             className="facebook"
    //             href={getSocialMediaLinks("fa")}
    //             target="_blank"
    //             rel="noreferrer">
    //             <IconContext.Provider value={iconStyleValue}>
    //               <RiFacebookFill />
    //             </IconContext.Provider>
    //           </a>
    //           <a
    //             className=""
    //             href={getSocialMediaLinks("yt")}
    //             target="_blank"
    //             rel="noreferrer">
    //             <IconContext.Provider
    //               value={{
    //                 ...iconStyleValue,
    //                 style: {
    //                   cursor: "pointer",
    //                   color: "white",
    //                   background: "red",
    //                 },
    //               }}>
    //               <FaYoutube />
    //             </IconContext.Provider>
    //           </a>
    //           <a
    //             className="linkde"
    //             href={getSocialMediaLinks("in")}
    //             target="_blank"
    //             rel="noreferrer">
    //             <IconContext.Provider value={iconStyleValue}>
    //               <RiLinkedinFill />
    //             </IconContext.Provider>
    //           </a>
    //           <a
    //             className=""
    //             href={getSocialMediaLinks("twit")}
    //             target="_blank"
    //             rel="noreferrer">
    //             <IconContext.Provider
    //               value={{
    //                 ...iconStyleValue,
    //                 style: {
    //                   cursor: "pointer",
    //                   color: "white",
    //                   background: "black",
    //                 },
    //               }}>
    //               <RiTwitterXFill />
    //             </IconContext.Provider>
    //           </a>
    //         </div>
    //       </div>
    //     </section>
    //   </div>
    //   {/* Footer text */}
    //   <div className="border-t text-center md:mt-10 p-6">
    //     <p className="copyright">All Rights Reserved Test © Copyright 2023</p>
    //   </div>
    // </footer>
  );
};

export default Footer;
