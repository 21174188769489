import React, { useEffect, useState } from "react";
import Tiles from "../common/tiles";
import { getStaticData, imageUrl } from "../common/helperFunction";

const Services = () => {
  const [tiles, setTiles] = useState([]);
  useEffect(() => {
    setTiles(getStaticData("tiles"));
  }, []);
  return (
    <div id="services" className="w-full px-4 py-4 sm:px-6 lg:px-8">
      <dir className="flex justify-center items-center">
        <h2 className="text-black text-3xl">
          <strong>Our services</strong>
        </h2>
      </dir>
      <div className="service">
        {tiles.map((tile) => {
          return (
            <div key={tile.id}>
              <Tiles
                url={imageUrl(tile.url)}
                alt={tile.alt}
                heading={tile.heading}
                about={tile.about}
                route={tile.route}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Services;
