import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
// import { Link } from "react-router-dom";
import JobDetails from "../common/jobDetails/JobDetails";

const Career = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
  };
  const jobList = [
    {
      title: "Software Engineer",
      company: "Tech Co.",
      location: "Cityville, USA",
      description: "Developing and maintaining web applications.",
    },
    {
      title: "UI/UX Designer",
      company: "Design Studio",
      location: "Creativity Town, USA",
      description: "Creating visually appealing and user-friendly designs.",
    },
    {
      title: "Data Scientist",
      company: "Data Analytics Inc.",
      location: "Datatown, USA",
      description: "Analyzing and interpreting complex data sets.",
    },
  ];
  return (
    <div id="career" className="career w-full px-4 py-4 sm:px-6 lg:px-8">
      <dir className="flex justify-center items-center">
        <h2 className="text-black text-3xl">
          <strong>Career</strong>
        </h2>
      </dir>
      <Slider {...settings}>
        {jobList.map((job, index) => (
          <div key={index}>
            <JobDetails />
            {/* <div className="career-tab">
              <div>
                <h3 className="text-black">
                  <strong>{job.title}</strong>
                </h3>
                <p>
                  <strong>Company:</strong> {job.company}
                </p>
                <p>
                  <strong>Location:</strong> {job.location}
                </p>
                <p>{job.description}</p>
                <p>
                  <Link to="/jobDesc" target="_blank">
                    Link
                  </Link>
                </p>
              </div>
            </div>
           */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Career;
