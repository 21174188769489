import React from "react";
// import { imageUrl } from "../common/helperFunction";
// import { TfiThought } from "react-icons/tfi";
// import { IconContext } from "react-icons";

const About = () => {
  return (
    <div id="about" >
      <div className="about-head" style={{marginTop:"84px"}}>Why Choose Gupio Parking Solutions?</div>
      <div className="about-card-parking-div">
        <div className="about-card">
          <img src="assets/Images/Group 68.png" alt="about" width="100px" />
          <div className="about-head-title">Effortless Parking Solutions</div>
          <div className="about-desc">Gupio Parking Solutions excels in simplifying parking complexities. Our tailored strategies for various environments, from corporate to healthcare, utilize smart technology for efficient space management. We enhance parking experiences, allowing entities to concentrate on their main operations while we smoothly handle the parking details.</div>
        </div>
        <div className="about-card">
          <img src="assets/Images/Group 69.png" alt="about" width="100px" />
          <div className="about-head-title">Excellence in Parking Services</div>
          <div className="about-desc">Gupio goes beyond basic parking management. We provide premium valet services, real-time app-based tracking, and ensure compliance with parking regulations. Our commitment to excellence in service offers you more than just convenience; it offers peace of mind, making us your trusted partner in parking solutions.</div>
        </div>
      </div>
      <div className="about-head" style={{marginTop:"84px"}}>Why Choose Gupio Garage for Services?</div>
      <div className="about-card-parking-div">
        <div className="about-card">
          <img src="assets/Images/Group 70.png" alt="about" width="100px" />
          <div className="about-head-title">Unmatched Expertise and Convenience</div>
          <div className="about-desc">At Gupio Garage, we redefine motorcycle service excellence. Our adept mechanics, equipped with the latest tools and techniques, ensure your motorcycle receives expert care, from routine checks to complex repairs. Emphasizing convenience, our doorstep service brings this top-notch maintenance right to your location, saving you time and hassle.</div>
        </div>
        <div className="about-card">
          <img src="assets/Images/Group 71.png" alt="about" width="100px" />
          <div className="about-head-title">Quality, Transparency, and Comprehensive Care</div>
          <div className="about-desc">We're committed to using only genuine branded parts, ensuring your motorcycle's longevity and performance. Transparency is key in our services, with clear billing and trustworthy advice. Beyond repairs, we offer comprehensive care, including RTO assistance and prompt roads.</div>
        </div>
      </div>
    </div>
  );
};

export default About;
