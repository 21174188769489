import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";

const MainBanner = ({ children }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
  };
  return (
    <div className="full">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default MainBanner;
