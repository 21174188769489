import React from "react";
import { Link } from "react-router-dom";
import MainBanner from "../common/banner/MainBanner";

const Home = () => {
  console.log(process.env);
  return (
    <div
      id="home"
      className="">
      <MainBanner>
        <div className="banner-parking">
          <div>
            <h1 className="max-w-[600px] text-5xl font-bold" style={{color:"#F3F3F3"}}>
              <span style={{color:"#E4B70B"}}>Gupio</span> Parking Solutions
            </h1>
            <p className="max-w-[600px] text-lg font-normal" style={{color:"#F3F3F3"}}>
              Innovative, tech-driven parking solutions for various
              establishments, ensuring safety and convenience.
            </p>
            <button className="max-w-[250px] text-black font-semibold p-3" style={{borderRadius:"10px", marginTop:"30px", backgroundColor:"#0056B3", color:"#fff"}}>
              <Link to="services/:parking">Know More</Link>
            </button>
          </div>
        </div>
        <div className="banner-garage">
          <div>
            <h1 className="max-w-[600px] text-5xl font-bold" style={{color:"#F3F3F3"}}>
            <span style={{color:"#E4B70B"}}>Gupio</span> Garage Service
            </h1>
            <p className="max-w-[600px] text-lg font-normal" style={{color:"#F3F3F3"}}>
              Premier multibrand vehicle services by certified mechanics,
              offering comprehensive care from maintenance to roadside
              assistance.
            </p>
            <button className="max-w-[250px] text-black font-semibold p-3" style={{borderRadius:"10px", marginTop:"30px", backgroundColor:"#0056B3", color:"#fff"}}>
              <Link to="services/:mechanic">Know More</Link>
            </button>
          </div>
        </div>
        <div className="banner-wash">
          <div>
            <h1 className="max-w-[600px] text-5xl font-bold" style={{color:"#F3F3F3"}}>
            <span style={{color:"#E4B70B"}}>Gupio</span> Washing Centre
            </h1>
            <p className="max-w-[600px] text-lg font-normal" style={{color:"#F3F3F3"}}>
              Our state-of-the-art facilities are equipped with cutting-edge
              technology, ensuring eco-friendly and efficient cleaning for both
              bikes and cars.
            </p>
            <button className="max-w-[250px] text-black font-semibold p-3" style={{borderRadius:"10px", marginTop:"30px", backgroundColor:"#0056B3", color:"#fff"}}>
              <Link to="services/:washing">Know More</Link>
            </button>
          </div>
        </div>
      </MainBanner>
      <div className="w-full px-4 py-4 sm:px-6 lg:px-8">
        <section></section>
      </div>
    </div>
  );
};

export default Home;
