import React, { useEffect, useState } from "react";
import { getStaticData } from "../common/helperFunction";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "../main/Blog.css";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    setBlogs(getStaticData("blogs"));
  }, []);

  return (
    <div id="blog" className="new-blog-container">
      <div className="blogs-text">Blogs</div>
      <div className="new-blog-cards-container">
        {blogs.map((blog, index) => (
          <Link to={`/blog/${index}`} key={index} className="blog-link">
            <div className="new-blog-card">
              <img alt={blog.alt} src={blog.image} style={{ width: "100%" }} />
              <div className="new-blog-date">{blog.date}</div>
              <div className="new-blog-title">{blog.title}</div>
              <div className="new-blog-content">
                {blog.content.length > 50
                  ? blog.content.slice(0, 50) + "..."
                  : blog.content}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Blog;
