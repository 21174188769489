import React from "react";
import { Link } from "react-router-dom";

const Tiles = ({ url, alt, heading, about, route }) => {
  return (
    <Link to={`services/:${route}`}>
    <div className="tile">
      <div>
        <img className="w-24 mb-4" src={url} alt={alt}/>
      </div>
      <div>
        <h2 className="heading">{heading}</h2>
        <div className="about">{about}</div>
      </div>
      <div className="readme">
          <strong>– Read more</strong>
      </div>
    </div>
    </Link>
  );
};

export default Tiles;
